import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components";
import { OrderTypes, ProductProps } from "../../interfaces/ProductInterfaces";
import { ProductBrandsHeader } from "./ProductBrandsHeader";
import { ProductsListView } from "./ProductsListView";
import Title from "../Title";
import { useSelector } from "react-redux";
import { StoreProps } from "../../interfaces/ReducerInterfaces";
import { initialJsonProductProps } from "../../utils/Jsons";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";
import { formatPrice, getProductsShowed, sliceList, sortAZ, sortAscending, sortByDefault, sortDescending, sortZA } from "../../utils/Utilities";
import { useLogEvent } from "../../hooks/useLogEvent";
import { ProductPagination } from "./ProductPagination";

const ContentContainer = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-self: center;
    z-index: 4;
    position: relative;
`

const Target = styled.div`
    height: 1px;
    width: 1px;
    position: absolute;
    top: -12vh;
`

export const Catalog = () => {

    const { products, checkingProducts, checkingBrands, profit } = useSelector((state: StoreProps) => state.fallherramientas);

    const location = useLocation();

    const history = useHistory();

    const params = new URLSearchParams(location.search);

    const [loadProductsPage, setLoadProductsPage] = useState<boolean>(false);

    const [searchProduct, setSearchProduct] = useState<string>(params.get('s') !== null ? params.get('s')! : '');

    const [brandSelected, setBrandSelected] = useState<number | null>(params.get('b') !== null ? parseInt(params.get('b')!) : null);

    const [productsOrder, setProductsOrder] = useState<OrderTypes>(params.get('o') !== null ? params.get('o')! as OrderTypes : '-');

    const [productsCurrentPage, setProductsCurrentPage] = useState<number>(params.get('p') !== null ? parseInt(params.get('p')!) : 1);

    const { handleLogEvent } = useLogEvent();

    const getFilteredProductsList = (list: ProductProps[], brandSelected: number | null, searchProduct: string, currentPage: number, sortBy?: OrderTypes) => {

        let filteredList = list

        if (brandSelected !== null) {
            filteredList = filteredList.filter((value) => { return value.brandId === brandSelected });
        }

        if (searchProduct.trim() !== '') {
            filteredList = filteredList.filter((value) => { return value.name.toLowerCase().includes(searchProduct.toLocaleLowerCase().trim()) || value.model.toLowerCase().includes(searchProduct.toLocaleLowerCase().trim()) });
        }

        switch (sortBy) {
            case '0-9':
                filteredList = sortAscending(filteredList, profit ?? 1);
                break;
            case '9-0':
                filteredList = sortDescending(filteredList, profit ?? 1);
                break;
            case 'A-Z':
                filteredList = sortAZ(filteredList);
                break;
            case 'Z-A':
                filteredList = sortZA(filteredList);
                break;
            default:
                filteredList = sortByDefault(filteredList);
                break
        }

        return filteredList;

    }

    const getFilteredProductsListByPage = (list: ProductProps[], brandSelected: number | null, searchProduct: string, currentPage: number, sortBy?: OrderTypes) => {

        let filteredList = getFilteredProductsList(list, brandSelected, searchProduct, currentPage, sortBy);

        const { initial, final } = getProductsShowed(currentPage, list.length);

        filteredList = sliceList(filteredList, initial - 1, final - 1);

        return filteredList;

    }

    useEffect(() => {

        if (productsCurrentPage === 1) {

            params.delete('p');

            history.push({ search: params.toString() });
        }

        if (searchProduct.trim() === '') {

            params.delete('s');

            history.push({ search: params.toString() });
        }

        if (productsOrder === '-') {

            params.delete('o');

            history.push({ search: params.toString() });
        }

        if (brandSelected === null) {

            params.delete('b');

            history.push({ search: params.toString() });
        }

    }, [productsCurrentPage, searchProduct, productsOrder, brandSelected])


    useEffect(() => {

        setTimeout(() => {
            setLoadProductsPage(false)
        }, 1500);

    }, [loadProductsPage])

    return (


        <ContentContainer>

            <Target id="products" />

            <Title
                title="CONOCE NUESTRO CATALOGO"
            />

            {
                checkingBrands || checkingProducts
                    ? <LoadingComponent />
                    : (

                        <>

                            <ProductBrandsHeader
                                productsOrder={productsOrder}
                                setProductsOrder={setProductsOrder}
                                brandSelected={brandSelected}
                                setBrandSelected={setBrandSelected}
                                searchProduct={searchProduct}
                                setSearchProduct={setSearchProduct}
                                productsCurrentPage={productsCurrentPage}
                                setProductsCurrentPage={setProductsCurrentPage}
                                lengthList={getFilteredProductsList(products, brandSelected, searchProduct, productsCurrentPage).length}
                            />

                            {
                                !loadProductsPage ? (

                                    <ProductsListView
                                        productSearched={searchProduct}
                                        data={getFilteredProductsListByPage(products, brandSelected, searchProduct, productsCurrentPage, productsOrder)}
                                        onClickProduct={(product: ProductProps) => {

                                            const price = profit !== null ? `AR$ ${product.customProfit !== null && product.customProfit !== undefined && product.customProfit >= 0 ? formatPrice(product.price + product.customProfit) : formatPrice(product.price * (1 + (profit! / 100)))}` : '-'

                                            let message = `https://wa.me/+5491133133735?text=Hola FAL Herramientas! Quiero solicitar el producto: %0a %0a - *Producto:* ${product.name} [${product.model}] ${price !== '-' ? `%0a - *Valor de contado:* ${price}` : ''} %0a %0a Aguardo a su respuesta. %0a ¡Muchas gracias!`;

                                            handleLogEvent('click_boton_solicitar_por_whatsapp', { name: product.name });

                                            window.open(message, 'blank')

                                        }}

                                    />

                                ) : <LoadingComponent />

                            }

                            <ProductPagination
                                productsCurrentPage={productsCurrentPage}
                                setProductsCurrentPage={setProductsCurrentPage}
                                lengthList={getFilteredProductsList(products, brandSelected, searchProduct, productsCurrentPage).length}
                                onClickChangePage={() => {
                                    setLoadProductsPage(true);
                                }}

                            />

                        </>

                    )

            }

        </ContentContainer>

    )
}
